.wrapper {
  display: flex;
  flex-direction: row;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.title {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .title {
    display: none;
  }
}

.imageWrapper {
  flex-basis: 0 0 auto;
}

.image {
  max-width: 400px;
  margin-bottom: 18px;
  margin-right: 36px;
  height: 220px;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .image {
    display: none;
  }
}