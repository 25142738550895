.label {
  margin-bottom: 9px;
  margin-left: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.link {
  text-decoration: none;
}
