:local .hiddenInput {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:local .hiddenInput:global(.focus-visible) + .container .checkbox {
  outline: 2px solid #666;
}

:local .hiddenInput:focus-visible + .container .checkbox {
  outline: 2px solid #666;
}

:local .container {
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
}

:local .container:hover .checkboxUnchecked {
  border: thin solid #222;
}

:local .checkboxChecked {
  background: #222;
}

:local .label {
  color: #222;
  margin-left: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

:local .label.small {
  margin-left: 8px;
  font-size: 12px;
  font-weight: 300;
}

:local .label.medium {
  margin-left: 9px;
  font-size: 14px;
  font-weight: 300;
}

:local .labelContainerDisabled {
  cursor: not-allowed;
}

:local .labelDisabled {
  color: #888;
}

:local .children {
  margin-left: 38px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

:local .childrenDisabled {
  color: #888;
}

:local .checkbox {
  box-sizing: border-box;
  min-width: 20px;
  height: 20px;
  margin-top: 1px;
  display: flex;
}

:local .checkbox.small {
  min-width: 16px;
  height: 16px;
}

:local .checkboxUnchecked {
  background: #fff;
  border: thin solid #bbb;
}

:local .checkboxUnchecked .checkmark {
  display: none;
}

:local .checkmark {
  color: #fff;
  fill: #fff;
  width: 14px;
  height: 14px;
  margin: auto;
}

:local .checkmark.small {
  width: 10px;
  height: 10px;
}

:local .checkboxDisabled {
  background-color: #ddd !important;
  border-color: #ddd !important;
}

:local .circleCheckbox {
  border-radius: 50%;
}

:local .checkboxError {
  border-color: #c00;
}

:local .container:hover .checkboxError {
  border-color: #c00;
}
