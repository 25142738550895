.container {
  background-color: #fbfbfb;
  border: 1px solid #f3f3f3;
  padding: 18px;
}

@media (width <= 768px) {
  .container {
    padding: 27px;
  }
}
