:local .stopBodyScroll {
  overflow: hidden;
}
@media (max-width: 1024px) {
  :local .stopBodyScroll {
    position: fixed !important;
  }
}
:local .overlayDefault {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10030;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.3s ease;
}
:local .overlayFadeStart {
  opacity: 0;
}
:local .overlayDefaultOpening {
  opacity: 1;
}
:local .overlayDefaultClosing {
  opacity: 0;
}
:local .modalDefault {
  box-sizing: border-box;
  position: absolute;
  max-width: 100%;
  width: 540px;
  transform: translateX(-50%);
  top: 36px;
  left: 50%;
  min-height: 250px;
  max-height: calc(100% - 72px);
  background-color: #fff;
  color: #222;
  overflow: auto;
  outline: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}
@media (max-width: 768px) {
  :local .modalDefault {
    width: 100%;
    top: 0;
    max-height: 100%;
    height: 100%;
  }
}
@media (min-width: 569px) {
  :local .modalMedium {
    width: 720px;
  }
}
@media (min-width: 569px) {
  :local .modalLarge {
    width: 960px;
  }
}
@media (min-width: 569px) {
  :local .modalFullscreen {
    width: 100%;
    height: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
  }
}
:local .modalNoOverlay {
  z-index: 10030;
  position: fixed;
  transition: none;
}
:local .modalPositionBottom {
  top: auto;
  transform: translate(-50%, 0);
}
@media (min-width: 569px) {
  :local .modalPositionBottom {
    bottom: 36px;
  }
}
@media (max-width: 768px) {
  :local .modalPositionBottom {
    bottom: 0;
  }
}
:local .modalPositionRightTop {
  top: 18px;
  right: 18px;
  transform: none;
  left: initial;
}
@media (max-width: 768px) {
  :local .modalPositionRightTop {
    top: 0;
    right: 0;
  }
}
@media (min-width: 569px) {
  :local .modalPositionCenter {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 768px) {
  :local .mobileModalSizeContentHeight {
    bottom: 0;
    top: auto;
    height: auto;
    min-height: auto;
    max-height: calc(100vh - 90px);
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
  }
}
:local .slideBottom {
  bottom: 0;
  top: auto;
}
:local .slideBottomHidden {
  transform: translate(-50%, 100%);
}
:local .slideBottomVisible {
  transform: translate(-50%, 0);
}
:local .slideRight {
  top: 0;
  right: 0;
  left: auto;
  height: 100%;
  max-height: 100%;
}
:local .slideRightHidden {
  transform: translate(100%, 0%);
}
:local .slideRightVisible {
  transform: translate(0%, 0%);
}
:local .modalHidden {
  display: none;
}