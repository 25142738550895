.closeButton {
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 30px;
  padding: 5px;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
}
.closeButton.translucentWhite {
  background-color: rgba(255, 255, 255, 0.7);
}
.closeButton svg {
  fill: #222;
}

.closeButtonDisabled {
  cursor: not-allowed;
}