.wrapper {
  flex-direction: row;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  display: flex;
}

.title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

@media (width <= 768px) {
  .title {
    display: none;
  }
}

.imageWrapper {
  flex-basis: 0 0 auto;
}

.image {
  border-radius: 50%;
  max-width: 400px;
  height: 220px;
  margin-bottom: 18px;
  margin-right: 36px;
}

@media (width <= 768px) {
  .image {
    display: none;
  }
}
