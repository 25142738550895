.container {
  position: relative;
  padding: 0 0 0 27px;
  margin-bottom: 27px;
}
@media (max-width: 768px) {
  .container {
    margin-bottom: 0;
  }
}

.title {
  margin: 0;
}
@media (max-width: 768px) {
  .title {
    margin: 18px 0;
  }
}