.isHidden,
.isHidden + .triangle {
  visibility: hidden;
}

.isTransparent,
.isTransparent + .triangle {
  opacity: 0;
}

.container {
  display: block;
  position: absolute;
  z-index: 10;
  color: #222;
  background: #fff;
  box-shadow: 0 1px 5px rgba(34, 34, 34, 0.3);
  cursor: default;
  transition: opacity;
  word-break: break-word;
  hyphens: auto;
}

.bump {
  z-index: 10;
}

.low {
  z-index: 100;
}

.middle {
  z-index: 200;
}

.high {
  z-index: 300;
}

.bottom,
.top {
  box-shadow: 1px 4px 15px 0 rgba(34, 34, 34, 0.23);
}

.top + .triangle {
  transform: rotate(180deg) translateZ(0);
  filter: drop-shadow(-1px -4px 2px #bbb);
}

.left,
.right + .triangle {
  filter: drop-shadow(0 -3px 2px rgba(136, 136, 136, 0.2));
}

.left + .triangle {
  transform: rotate(90deg) translateZ(0);
}

.right + .triangle {
  transform: rotate(-90deg) translateZ(0);
}

.large {
  width: 250px;
}

.xLarge {
  width: 444px;
}
.xLarge .inner {
  padding: 18px;
}

.autoWidth {
  min-width: 90px;
}
.autoWidth .inner {
  white-space: nowrap;
}

.small {
  min-width: 100px;
  white-space: nowrap;
  background: #222;
  color: #fff;
}
.small .inner {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 600;
  padding: 7px 9px;
  letter-spacing: 0.5px;
}
.small + .triangle .triangleIcon {
  fill: #222;
}

.coachmark {
  width: 320px;
}

.medium {
  width: 240px;
}

.isHidden {
  width: 0;
  overflow: hidden;
}

.inner {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  position: relative;
  padding: 23px 27px;
  line-height: 1.4;
  text-align: left;
}
.inner.smallPadding {
  padding: 9px;
}
.inner.noPadding {
  padding: 0;
}

.close {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 12px;
  height: 12px;
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
  fill: #888;
}
.close:hover {
  opacity: 0.9;
  fill: #222;
}

.triangle {
  position: absolute;
  text-align: start;
}

.triangleIcon {
  position: inherit;
  max-height: 100%;
  max-width: 100%;
  fill: #fff;
}

.triangleStroke {
  stroke: rgba(136, 136, 136, 0.1);
}
:local(.bottom) .triangleStroke {
  stroke: rgba(136, 136, 136, 0.07);
}