.isHidden, .isHidden + .triangle {
  visibility: hidden;
}

.isTransparent, .isTransparent + .triangle {
  opacity: 0;
}

.container {
  z-index: 10;
  color: #222;
  cursor: default;
  word-break: break-word;
  hyphens: auto;
  background: #fff;
  transition: opacity;
  display: block;
  position: absolute;
  box-shadow: 0 1px 5px #2222224d;
}

.bump {
  z-index: 10;
}

.low {
  z-index: 100;
}

.middle {
  z-index: 200;
}

.high {
  z-index: 300;
}

.bottom, .top {
  box-shadow: 1px 4px 15px #2222223b;
}

.top + .triangle {
  filter: drop-shadow(-1px -4px 2px #bbb);
  transform: rotate(180deg)translateZ(0);
}

.left, .right + .triangle {
  filter: drop-shadow(0 -3px 2px #8883);
}

.left + .triangle {
  transform: rotate(90deg)translateZ(0);
}

.right + .triangle {
  transform: rotate(-90deg)translateZ(0);
}

.large {
  width: 250px;
}

.xLarge {
  width: 444px;
}

.xLarge .inner {
  padding: 18px;
}

.autoWidth {
  min-width: 90px;
}

.autoWidth .inner {
  white-space: nowrap;
}

.small {
  white-space: nowrap;
  color: #fff;
  background: #222;
  min-width: 100px;
}

.small .inner {
  letter-spacing: .5px;
  padding: 7px 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 600;
}

.small + .triangle .triangleIcon {
  fill: #222;
}

.coachmark {
  width: 320px;
}

.medium {
  width: 240px;
}

.isHidden {
  width: 0;
  overflow: hidden;
}

.inner {
  text-align: left;
  padding: 23px 27px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
  position: relative;
}

.inner.smallPadding {
  padding: 9px;
}

.inner.noPadding {
  padding: 0;
}

.close {
  cursor: pointer;
  fill: #888;
  width: 12px;
  max-width: 100%;
  height: 12px;
  max-height: 100%;
  position: absolute;
  top: 8px;
  right: 8px;
}

.close:hover {
  opacity: .9;
  fill: #222;
}

.triangle {
  text-align: start;
  position: absolute;
}

.triangleIcon {
  position: inherit;
  fill: #fff;
  max-width: 100%;
  max-height: 100%;
}

.triangleStroke {
  stroke: #8888881a;
}

:local(.bottom) .triangleStroke {
  stroke: #88888812;
}
