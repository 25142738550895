.container {
  margin-bottom: 27px;
  padding: 0 0 0 27px;
  position: relative;
}

@media (width <= 768px) {
  .container {
    margin-bottom: 0;
  }
}

.title {
  margin: 0;
}

@media (width <= 768px) {
  .title {
    margin: 18px 0;
  }
}
