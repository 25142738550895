.radioLabel {
  margin-left: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.radioWrapper {
  padding-bottom: 9px;
}

.link {
  text-decoration: none;
  display: block;
}
