.container {
  padding: 0 0 0 27px;
}
@media (max-width: 1024px) {
  .container {
    padding: 0 0 0 9px;
  }
}
@media (max-width: 768px) {
  .container {
    padding: 0;
  }
}

.plusIcon {
  width: 15px;
  height: 15px;
  margin: 0 0 -2px 5px;
}

.refineWrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  color: #c2a661;
  position: relative;
  cursor: pointer;
  padding: 0 18px 18px;
}