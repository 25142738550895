:local .hiddenInput {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
:local .hiddenInput:global(.focus-visible) + .container .checkbox {
  outline: #666 solid 2px;
}
:local .hiddenInput:focus-visible + .container .checkbox {
  outline: none;
  outline: #666 solid 2px;
}
:local .container {
  display: flex;
  cursor: pointer;
  margin-bottom: 0;
}
:local .container:hover .checkboxUnchecked {
  border: thin solid #222;
}
:local .checkboxChecked {
  background: #222;
}
:local .label {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  color: #222;
  line-height: 1.5;
  margin-left: 18px;
}
:local .label.small {
  font-size: 12px;
  font-weight: 300;
  margin-left: 8px;
}
:local .label.medium {
  font-size: 14px;
  font-weight: 300;
  margin-left: 9px;
}
:local .labelContainerDisabled {
  cursor: not-allowed;
}
:local .labelDisabled {
  color: #888;
}
:local .children {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  margin-left: 38px;
}
:local .childrenDisabled {
  color: #888;
}
:local .checkbox {
  display: flex;
  box-sizing: border-box;
  height: 20px;
  min-width: 20px;
  margin-top: 1px;
}
:local .checkbox.small {
  height: 16px;
  min-width: 16px;
}
:local .checkboxUnchecked {
  border: thin solid #bbb;
  background: #fff;
}
:local .checkboxUnchecked .checkmark {
  display: none;
}
:local .checkmark {
  color: #fff;
  fill: #fff;
  margin: auto;
  width: 14px;
  height: 14px;
}
:local .checkmark.small {
  width: 10px;
  height: 10px;
}
:local .checkboxDisabled {
  background-color: #ddd !important;
  border-color: #ddd !important;
}
:local .circleCheckbox {
  border-radius: 50%;
}
:local .checkboxError {
  border-color: #cc0000;
}
:local .container:hover .checkboxError {
  border-color: #cc0000;
}