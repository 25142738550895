.modalBody {
  padding: 0 36px 36px;
}

@media (width <= 768px) {
  .modalBody {
    padding: 0 18px 18px;
  }
}

.modalBody.small {
  margin-top: 18px;
}

.modalBody.medium {
  margin-top: 27px;
}

.modalBody.large {
  margin-top: 36px;
}
