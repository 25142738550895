.hiddenInput {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.hiddenInput:global(.focus-visible) + .labelContainer .radioButton {
  outline: #666 solid 2px;
}

.hiddenInput:focus-visible + .labelContainer .radioButton {
  outline: none;
  outline: #666 solid 2px;
}

.labelContainer {
  position: relative;
  display: flex;
  align-items: top;
  cursor: pointer;
  color: #222;
}

.labelContainerDisabled {
  cursor: not-allowed;
  color: #888;
}

.radioButton {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  border: thin solid #ddd;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  transition: all 0.05s linear;
  margin-top: 2px;
  overflow: hidden;
}
.radioButton::after {
  content: "";
  width: 0;
  height: 0;
  border-radius: 100%;
  transition: all 0.05s linear;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #222;
}

.buttonChecked {
  border: thin solid #222;
}
.buttonChecked::after {
  width: 12px;
  height: 12px;
}

.buttonDisabled {
  border: thin solid #ddd;
  background-color: #f3f3f3;
}
.buttonDisabled::after {
  background-color: #ddd;
}

.label {
  flex-grow: 1;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  margin-left: 18px;
  line-height: 1.5;
}

.children {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  margin-left: 38px;
  margin-top: 9px;
}

.childrenDisabled {
  color: #888;
}