.logoWrap {
  height: 35px;
  margin: 9px auto;
  position: relative;
}

.logoWrap:after {
  content: "";
  background: #c2a661;
  width: 70px;
  height: 2px;
  margin: 9px auto 0;
  display: block;
  position: relative;
  top: 40px;
}

.wrap {
  height: 325px;
  padding: 0 0 45px 18px;
}

@media (width <= 768px) {
  .wrap {
    height: 330px;
  }
}

.container {
  border: 1px solid #f3f3f3;
  max-width: 312px;
  height: 100%;
  display: block;
  position: relative;
}

.imageLink {
  display: block;
}

.dealerLink {
  text-decoration: none;
}

.imageWrap {
  height: 114px;
  max-height: 114px;
  padding: 0;
  position: relative;
  overflow: hidden;
}

@media (width <= 768px) {
  .imageWrap {
    max-height: 105px;
  }
}

.image {
  max-height: 100%;
  display: block;
}

.logo {
  height: 100%;
}

.copy {
  color: #222;
  max-height: 105px;
  padding: 27px 18px 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  overflow: hidden;
}

@media (width <= 768px) {
  .copy {
    max-height: 96px;
    padding: 18px 9px 0;
  }
}

.iconsWrapper {
  text-align: right;
  color: #222;
  width: 50px;
  height: 20px;
  position: absolute;
  bottom: 20px;
  right: 15px;
}

@media (width <= 768px) {
  .iconsWrapper {
    bottom: 9px;
  }
}

.icons {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  display: inline-block;
  position: relative;
}
