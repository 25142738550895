.container {
  text-align: center;
  width: 100%;
  padding-top: 27px;
  padding-bottom: 27px;
}

@media (width <= 768px) {
  .container {
    margin-top: 27px;
  }
}

.pages {
  display: inline-flex;
  position: relative;
}

@media (width <= 768px) {
  .pages {
    display: inline-block;
  }
}

.itemWrapper {
  text-align: center;
  color: #222;
  width: 48px;
  margin-right: 18px;
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
}

@media (width <= 1024px) {
  .itemWrapper {
    margin-right: 15px;
  }
}

@media (width <= 768px) {
  .itemWrapper {
    width: 42px;
    margin-right: 9px;
  }
}

.item {
  color: #222;
  padding: 9px 0;
  display: block;
}

.separator {
  width: 18px;
}

.button {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  position: relative;
}

.button, .button:hover {
  color: #222;
}

.link {
  border: 1px solid #bbb;
  text-decoration: none;
}

.link:hover {
  cursor: pointer;
  border-color: #c2a661;
}

.selected {
  background-color: #f3f3f3;
  border: 1px solid #bbb;
}

.nextButton, .previousButton {
  align-items: center;
  text-decoration: none;
  display: flex;
  bottom: calc(100% + 18px);
}

@media (width <= 768px) {
  .nextButton {
    position: absolute;
    right: 0;
  }
}

.previousButton {
  margin-right: 18px;
}

@media (width <= 768px) {
  .previousButton {
    position: absolute;
    left: 0;
  }
}

.svgArrowWrapper {
  width: 18px;
  height: 18px;
  margin-top: -2px;
  display: block;
}
