.label {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  margin-left: 9px;
  margin-bottom: 9px;
}

.link {
  text-decoration: none;
}