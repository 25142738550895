:local .stopBodyScroll {
  overflow: hidden;
}

@media (width <= 1024px) {
  :local .stopBodyScroll {
    position: fixed !important;
  }
}

:local .overlayDefault {
  z-index: 10030;
  background-color: #000000b3;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  display: flex;
  position: fixed;
  inset: 0;
}

:local .overlayFadeStart {
  opacity: 0;
}

:local .overlayDefaultOpening {
  opacity: 1;
}

:local .overlayDefaultClosing {
  opacity: 0;
}

:local .modalDefault {
  box-sizing: border-box;
  color: #222;
  background-color: #fff;
  outline: none;
  width: 540px;
  max-width: 100%;
  min-height: 250px;
  max-height: calc(100% - 72px);
  transition: transform .3s;
  position: absolute;
  top: 36px;
  left: 50%;
  overflow: auto;
  transform: translateX(-50%);
  box-shadow: 0 5px 15px #0003;
}

@media (width <= 768px) {
  :local .modalDefault {
    width: 100%;
    height: 100%;
    max-height: 100%;
    top: 0;
  }
}

@media (width >= 569px) {
  :local .modalMedium {
    width: 720px;
  }

  :local .modalLarge {
    width: 960px;
  }

  :local .modalFullscreen {
    width: 100%;
    height: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
  }
}

:local .modalNoOverlay {
  z-index: 10030;
  transition: none;
  position: fixed;
}

:local .modalPositionBottom {
  top: auto;
  transform: translate(-50%);
}

@media (width >= 569px) {
  :local .modalPositionBottom {
    bottom: 36px;
  }
}

@media (width <= 768px) {
  :local .modalPositionBottom {
    bottom: 0;
  }
}

:local .modalPositionRightTop {
  top: 18px;
  right: 18px;
  left: initial;
  transform: none;
}

@media (width <= 768px) {
  :local .modalPositionRightTop {
    top: 0;
    right: 0;
  }
}

@media (width >= 569px) {
  :local .modalPositionCenter {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (width <= 768px) {
  :local .mobileModalSizeContentHeight {
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    height: auto;
    min-height: auto;
    max-height: calc(100vh - 90px);
    top: auto;
    bottom: 0;
  }
}

:local .slideBottom {
  top: auto;
  bottom: 0;
}

:local .slideBottomHidden {
  transform: translate(-50%, 100%);
}

:local .slideBottomVisible {
  transform: translate(-50%);
}

:local .slideRight {
  height: 100%;
  max-height: 100%;
  top: 0;
  left: auto;
  right: 0;
}

:local .slideRightHidden {
  transform: translate(100%);
}

:local .slideRightVisible {
  transform: translate(0%);
}

:local .modalHidden {
  display: none;
}
