.filters {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  padding-bottom: 18px;
}

.title {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 24px;
  font-weight: 300;
  flex: 2 1 auto;
  line-height: 1;
  padding: 18px 0 27px 27px;
}