.filters {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.title {
  flex: 2 auto;
  padding: 18px 0 27px 27px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 24px;
  font-weight: 300;
  line-height: 1;
}
