.closeButton {
  cursor: pointer;
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  padding: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.closeButton.translucentWhite {
  background-color: #ffffffb3;
}

.closeButton svg {
  fill: #222;
}

.closeButtonDisabled {
  cursor: not-allowed;
}
