.container {
  padding-bottom: 14px;
}

.separator {
  border-bottom: 1px solid #ddd;
}

.title {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  line-height: 1;
  padding: 18px 0;
}