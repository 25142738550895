.container {
  padding: 0 0 0 27px;
}

@media (width <= 1024px) {
  .container {
    padding: 0 0 0 9px;
  }
}

@media (width <= 768px) {
  .container {
    padding: 0;
  }
}

.plusIcon {
  width: 15px;
  height: 15px;
  margin: 0 0 -2px 5px;
}

.refineWrapper {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #c2a661;
  cursor: pointer;
  padding: 0 18px 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  position: relative;
}
