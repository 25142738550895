.container {
  padding-top: 27px;
  padding-bottom: 27px;
  text-align: center;
  width: 100%;
}
@media (max-width: 768px) {
  .container {
    margin-top: 27px;
  }
}

.pages {
  position: relative;
  display: inline-flex;
}
@media (max-width: 768px) {
  .pages {
    display: inline-block;
  }
}

.itemWrapper {
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
  width: 48px;
  text-align: center;
  margin-right: 18px;
  color: #222;
}
@media (max-width: 1024px) {
  .itemWrapper {
    margin-right: 15px;
  }
}
@media (max-width: 768px) {
  .itemWrapper {
    margin-right: 9px;
    width: 42px;
  }
}

.item {
  display: block;
  padding: 9px 0;
  color: #222;
}

.separator {
  width: 18px;
}

.button {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  position: relative;
  text-align: center;
}
.button, .button:hover {
  color: #222;
}

.link {
  text-decoration: none;
  border: 1px solid #bbb;
}
.link:hover {
  border-color: #c2a661;
  cursor: pointer;
}

.selected {
  border: 1px solid #bbb;
  background-color: #f3f3f3;
}

.nextButton,
.previousButton {
  display: flex;
  align-items: center;
  bottom: calc(100% + 18px);
  text-decoration: none;
}

@media (max-width: 768px) {
  .nextButton {
    position: absolute;
    right: 0;
  }
}

.previousButton {
  margin-right: 18px;
}
@media (max-width: 768px) {
  .previousButton {
    position: absolute;
    left: 0;
  }
}

.svgArrowWrapper {
  display: block;
  margin-top: -2px;
  width: 18px;
  height: 18px;
}